// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 108.24 90.462"
      >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            transform="translate(-241.188 -408.216)"
            fill="url(#BG1)"
            d="M288.923,410.049l-47.257,83.217a3.622,3.622,0,0,0,3.15,5.411h32.661a3.622,3.622,0,0,0,3.223-1.969l8.2-15.986a3.622,3.622,0,0,1,6.385-.113l6.995,12.525a3.622,3.622,0,0,0,6.292.058l29.791-51.109A3.622,3.622,0,0,1,343.648,441h0a3.622,3.622,0,0,0,5.779-2.909V415.763a3.622,3.622,0,0,0-5.437-3.135l-18.914,10.95a3.622,3.622,0,0,0-.1,6.212l2.211,1.374A3.622,3.622,0,0,1,328.444,436l-18.833,33.8a3.622,3.622,0,0,1-6.239.151l-8.094-13a3.622,3.622,0,0,0-6.259.187L274,484.841a3.622,3.622,0,0,1-3.106,1.895l-5.956.128a3.622,3.622,0,0,1-3.218-5.426l27.193-47.3a3.622,3.622,0,0,1,6.248-.055l7.834,13.085a3.622,3.622,0,0,0,5.948.387l3.443-4.352a3.622,3.622,0,0,0,.265-4.11l-17.469-29.115A3.622,3.622,0,0,0,288.923,410.049Z"
          />
          <path
            transform="translate(-241.188 -408.216)"
            fill="url(#BG2)"
            d="M288.923,410.049l-47.257,83.217a3.622,3.622,0,0,0,3.15,5.411h32.661a3.622,3.622,0,0,0,3.223-1.969l8.2-15.986a3.622,3.622,0,0,1,6.385-.113l6.995,12.525a3.622,3.622,0,0,0,6.292.058l29.791-51.109A3.622,3.622,0,0,1,343.648,441h0a3.622,3.622,0,0,0,5.779-2.909V415.763a3.622,3.622,0,0,0-5.437-3.135l-18.914,10.95a3.622,3.622,0,0,0-.1,6.212l2.211,1.374A3.622,3.622,0,0,1,328.444,436l-18.833,33.8a3.622,3.622,0,0,1-6.239.151l-8.094-13a3.622,3.622,0,0,0-6.259.187L274,484.841a3.622,3.622,0,0,1-3.106,1.895l-5.956.128a3.622,3.622,0,0,1-3.218-5.426l27.193-47.3a3.622,3.622,0,0,1,6.248-.055l7.834,13.085a3.622,3.622,0,0,0,5.948.387l3.443-4.352a3.622,3.622,0,0,0,.265-4.11l-17.469-29.115A3.622,3.622,0,0,0,288.923,410.049Z"
          />
          <path
            transform="translate(-241.188 -408.216)"
            fill="url(#BG3)"
            d="M288.923,410.049l-47.257,83.217a3.622,3.622,0,0,0,3.15,5.411h32.661a3.622,3.622,0,0,0,3.223-1.969l8.2-15.986a3.622,3.622,0,0,1,6.385-.113l6.995,12.525a3.622,3.622,0,0,0,6.292.058l29.791-51.109A3.622,3.622,0,0,1,343.648,441h0a3.622,3.622,0,0,0,5.779-2.909V415.763a3.622,3.622,0,0,0-5.437-3.135l-18.914,10.95a3.622,3.622,0,0,0-.1,6.212l2.211,1.374A3.622,3.622,0,0,1,328.444,436l-18.833,33.8a3.622,3.622,0,0,1-6.239.151l-8.094-13a3.622,3.622,0,0,0-6.259.187L274,484.841a3.622,3.622,0,0,1-3.106,1.895l-5.956.128a3.622,3.622,0,0,1-3.218-5.426l27.193-47.3a3.622,3.622,0,0,1,6.248-.055l7.834,13.085a3.622,3.622,0,0,0,5.948.387l3.443-4.352a3.622,3.622,0,0,0,.265-4.11l-17.469-29.115A3.622,3.622,0,0,0,288.923,410.049Z"
          />
        </g>
      </svg>
    </Box>
  );
}
