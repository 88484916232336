// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  comments: getIcon('ic_comments'),
  student: getIcon('ic_student'),
  adminsUser: getIcon('ic_user'),
  dashboard: getIcon('ic_home'),
  graduationCap: getIcon('ic_graduation_cap'),
  communication: getIcon('ic_megaphone')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'administrativo',
    items: [
      { title: 'Home', path: PATH_DASHBOARD.admin.home, icon: ICONS.dashboard },
      { title: 'Cursos', path: PATH_DASHBOARD.admin.courses.list, icon: ICONS.graduationCap },
      { title: 'Admins', path: PATH_DASHBOARD.admin.admins.list, icon: ICONS.adminsUser },
      { title: 'Alunos', path: PATH_DASHBOARD.admin.students.list, icon: ICONS.student },
      {
        title: 'Comunicados',
        path: PATH_DASHBOARD.admin.communications.list,
        icon: ICONS.communication
      }
      // { title: 'Comentários', path: PATH_DASHBOARD.admin.comments, icon: ICONS.comments }
    ]
  }

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'aluno',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.app.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.app.pageFour },
  //         { title: 'Five', path: PATH_DASHBOARD.app.pageFive },
  //         { title: 'Six', path: PATH_DASHBOARD.app.pageSix }
  //       ]
  //     }
  //   ]
  // }
];

export default sidebarConfig;
