// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  admin: {
    home: path(ROOTS_DASHBOARD, '/Dashboard'),
    courses: {
      list: path(ROOTS_DASHBOARD, '/courses'),
      show: path(ROOTS_DASHBOARD, '/courses/show/:id'),
      add: path(ROOTS_DASHBOARD, '/courses/add'),
      edit: path(ROOTS_DASHBOARD, '/courses/edit/:id'),
      addLesson: path(ROOTS_DASHBOARD, '/courses/lessons/add/:courseId'),
      editLesson: path(ROOTS_DASHBOARD, '/courses/lessons/edit/:courseId/:id')
    },
    students: {
      list: path(ROOTS_DASHBOARD, '/Students'),
      show: path(ROOTS_DASHBOARD, '/Students/show/:id'),
      add: path(ROOTS_DASHBOARD, '/Students/add'),
      edit: path(ROOTS_DASHBOARD, '/Students/edit/:id')
    },
    admins: {
      list: path(ROOTS_DASHBOARD, '/users-admin'),
      show: path(ROOTS_DASHBOARD, '/users-admin/show/:id'),
      add: path(ROOTS_DASHBOARD, '/users-admin/add'),
      edit: path(ROOTS_DASHBOARD, '/users-admin/edit/:id'),
      profile: path(ROOTS_DASHBOARD, '/users-admin/profile/:id')
    },
    communications: {
      list: path(ROOTS_DASHBOARD, '/communications'),
      show: path(ROOTS_DASHBOARD, '/communications/show/:id'),
      add: path(ROOTS_DASHBOARD, '/communications/add'),
      edit: path(ROOTS_DASHBOARD, '/communications/edit/:id')
    },
    comments: path(ROOTS_DASHBOARD, '/comments')
  }
};
