import React, { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/Dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   )
        // },
        // {
        //   path: 'reset-password',
        //   element: <ResetPassword />
        // },
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    // Dashboard Routes
    {
      path: 'admin',
      element: (
        // <RoleBasedGuard accessibleRoles={[roles.ADMIN]}>
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
        // </RoleBasedGuard>
      ),
      children: [
        { element: <Navigate to="/admin/dashboard" replace /> },
        { path: 'dashboard', element: <Dashboard /> },
        {
          path: 'courses',
          children: [
            { element: <Navigate to="/admin/courses/list" replace /> },
            { path: 'list', element: <CourseList /> },
            { path: 'show/:id', element: <CourseShow /> },
            { path: 'add', element: <CourseForm /> },
            { path: 'edit/:id', element: <CourseForm /> },
            { path: 'lessons/edit/:courseId/:id', element: <LessonForm /> },
            { path: 'lessons/add/:courseId', element: <LessonForm /> }
          ]
        },
        {
          path: 'students',
          children: [
            { element: <Navigate to="/admin/students/list" replace /> },
            { path: 'list', element: <StudentList /> },
            { path: 'add', element: <StudentForm /> },
            { path: 'edit/:id', element: <StudentForm /> }
          ]
        },
        {
          path: 'users-admin',
          children: [
            { element: <Navigate to="/admin/users-admin/list" replace /> },
            { path: 'list', element: <AdminList /> },
            { path: 'add', element: <AdminForm /> },
            { path: 'edit/:id', element: <AdminForm /> },
            { path: 'profile/:id', element: <AdminProfile /> }
          ]
        },
        {
          path: 'communications',
          children: [
            { element: <Navigate to="/admin/communications/list" replace /> },
            { path: 'list', element: <CommunicationList /> },
            { path: 'add', element: <CommunicationForm /> },
            { path: 'edit/:id', element: <CommunicationForm /> }
          ]
        },
        { path: 'comments', element: <PageTwo /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '500', element: <ServerError /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/admin" replace />
    }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/admin/Dashboard')));

// Course
const CourseList = Loadable(lazy(() => import('../pages/admin/Course')));
const CourseShow = Loadable(lazy(() => import('../pages/admin/Course/Show')));
const CourseForm = Loadable(lazy(() => import('../pages/admin/Course/Form')));
const LessonForm = Loadable(lazy(() => import('../pages/admin/Lesson/Form')));

// Student
const StudentList = Loadable(lazy(() => import('../pages/admin/Students')));
const StudentForm = Loadable(lazy(() => import('../pages/admin/Students/Form')));

// Admin
const AdminList = Loadable(lazy(() => import('../pages/admin/Admins')));
const AdminForm = Loadable(lazy(() => import('../pages/admin/Admins/Form')));
const AdminProfile = Loadable(lazy(() => import('../pages/admin/Admins/Show')));

// Communications
const CommunicationList = Loadable(lazy(() => import('../pages/admin/Announcements')));
const CommunicationForm = Loadable(lazy(() => import('../pages/admin/Announcements/Form')));

const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ServerError = Loadable(lazy(() => import('../pages/Page500')));
